<template lang="pug">
om-modal.new-campaign-integration-modal(
  name="new-campaign-integration"
  :width="850"
  :scrollable="true"
  @beforeOpen="beforeOpen"
)
  template(v-if="currentPage === PAGES.SELECT_GLOBAL_INTEGRATION")
    template(v-if="!hasEmailField")
      template(v-if="!addNew")
        template(slot="modal-header")
          .brand-modal-header-with-background
            .d-flex.align-items-center
              .flex-grow-1.font-weight-bold {{ $t('limitedIntegrations') }}
            .brand-modal-action-icon.cursor-pointer(
              @click="$modal.hide('new-campaign-integration')"
            )
              close-icon(:width="12" :height="12" color="#AAB1C1")
        template(slot="modal-body")
          div {{ $t('limitedIntegrationsLead') }}
          .mt-2 {{ $t('choosePreviouslySaved') }}
          .brand-integrations-holder
            .py-3.px-5(v-for="(integration, index) in integrationsByField")
              .brand-integration-row.d-flex.py-2.align-items-center(
                @click="createFromGlobal(integration)"
              )
                integration-logo.keep-logo-height.mx-2(:type="integration.type")
                .d-flex-1.flex-column
                  .font-weight-bold.mb-1
                    integration-name(
                      :name.sync="integrationsByField[index].data.name"
                      :integrationId="integration._id"
                      @successfulChange="successfulNameChange"
                      @failedChange="failedNameChange"
                    )
                  template(v-for="(value, fieldName) in integration.data")
                    .d-flex.align-items-center(
                      v-if="fieldName !== 'name' && !isOAuth(integration.type)"
                    )
                      span.mr-2(v-if="fieldName !== 'shopifyId'") {{ $t(`integrationFields.${fieldName}`) }}:
                      span.font-weight-bold.global-integration-value-ellipsis(
                        v-if="fieldName !== 'shopifyId'"
                        :title="value"
                      ) {{ value }}
                i.fas.fa-times.cursor-pointer.mr-3.p-2(@click.stop="removeGlobal(integration._id)")
        template(slot="modal-footer")
          .d-flex
            om-button.mx-auto(secondary @click="$modal.hide('new-campaign-integration')") {{ $t('cancel') }}
            om-button.mx-auto(primary @click="addNew = true") {{ $t('orAddANewOne') }}
      template(v-else)
        template(slot="modal-header")
          .brand-modal-header-with-background
            .d-flex.align-items-center
              .flex-grow-1.text-uppercase.font-weight-bold {{ $t('limitedIntegrations') }}
            .brand-modal-action-icon.cursor-pointer(
              @click="$modal.hide('new-campaign-integration')"
            )
              close-icon(:width="12" :height="12")
        template(slot="modal-body")
          .mb-2 {{ $t('limitedIntegrationsLead') }}
          .d-flex.flex-wrap.align-items-start.brand-integrations-holder
            .brand-integration(v-for="integration in filteredIntegrations")
              .brand-integration-clickable(@click="createFromNew(integration)")
                integration-logo(:type="integration")
                .font-size-0--75.text-center.mt-2 {{ $t(`integrations.${integration}.name`) }}
            template(v-if="!filteredIntegrations.length")
              .brand-integration-no-result {{ $t('noResults') }}
        template(slot="modal-footer")
          om-button.mx-auto(secondary v-if="hasGlobalIntegrations" @click="addNew = false") {{ $t('cancel') }}
    template(v-else)
      template(v-if="hasGlobalIntegrations && !addNew")
        template(slot="modal-header")
          .brand-modal-header-with-background
            .d-flex.align-items-center
              .flex-grow-1.font-weight-bold {{ $t('globalIntegrations') }}
            .brand-modal-action-icon.cursor-pointer(
              @click="$modal.hide('new-campaign-integration')"
            )
              close-icon(:width="12" :height="12" color="#AAB1C1")
        template(slot="modal-body")
          p {{ $t('choosePreviouslySaved') }}
          .brand-integrations-holder(ref="integrationsHolder")
            .py-3.px-5(
              v-for="(integration, index) in globalIntegrations"
              v-if="!integration.deprecated"
            )
              .brand-integration-row.d-flex.py-2.align-items-center(
                @click="createFromGlobal(integration)"
              )
                integration-logo.mx-2(:type="integration.type")
                .d-flex.flex-column
                  .font-weight-bold.mb-1
                    integration-name(
                      :name.sync="globalIntegrations[index].data.name"
                      :integrationId="integration._id"
                      @successfulChange="successfulNameChange"
                      @failedChange="failedNameChange"
                    )

                  template(
                    v-if="integration.type !== 'shopRenter' || (integration.type === 'shopRenter' && !integration.data.apiUrl)"
                  )
                    template(v-for="(value, fieldName) in integration.data")
                      .d-flex.align-items-center(
                        v-if="fieldName !== 'name' && !isOAuth(integration.type) && $te(`integrations.${integration.type}.tooltip.${fieldName}`)"
                      )
                        span.mr-2 {{ $t(`integrationFields.${fieldName}`) }}:
                        span.font-weight-bold.global-integration-value-ellipsis(:title="value") {{ value }}
                  .d-flex.align-items-center(v-else)
                    span.mr-2 {{ $t('integrations.shopRenter.labels.account') }}:
                    span.font-weight-bold.global-integration-value-ellipsis(
                      :title="integration.data.identifier"
                    ) {{ integration.data.identifier }}
                i.fas.fa-times.cursor-pointer.mr-3.p-2(@click.stop="removeGlobal(integration._id)")
        template(slot="modal-footer")
          om-button.mx-auto(primary @click="addNew = true") {{ $t('orAddANewOne') }}
      template(v-else)
        template(slot="modal-header")
          .brand-modal-header-with-background
            .d-flex.align-items-center
              .flex-grow-1.font-weight-bold {{ $t('integrationsTitle') }}
              input#searchText.w-25.form-control.form-control-search.mr-5(
                type="text"
                :placeholder="$t('search')"
                ref="integrationSearchInput"
                v-model="filter"
              )
              .brand-modal-action-icon.cursor-pointer(
                @click="$modal.hide('new-campaign-integration')"
              )
                close-icon(:width="12" :height="12" color="#AAB1C1")
        template(slot="modal-body")
          has-klaviyo-detection(
            v-if="domainHasKlaviyoDetection() && !hasKlaviyoCampaignIntegration()"
            @addKlaviyo="createFromNew('klaviyo')"
          )
          .d-flex.flex-wrap.align-items-start.brand-integrations-holder
            .brand-integration(v-for="integration in filteredIntegrations")
              .brand-integration-clickable(@click="createFromNew(integration)")
                integration-logo.keep-logo-height(:type="integration")
                .font-size-0--75.text-center.mt-2 {{ $t(`integrations.${integration}.name`) }}
            template(v-if="!filteredIntegrations.length")
              .brand-integration-no-result {{ $t('noResults') }}
        template(slot="modal-footer")
          om-button.mx-auto(secondary v-if="hasGlobalIntegrations" @click="addNew = false") {{ $t('cancel') }}
  template(v-if="currentPage === PAGES.CREATE_NEW_INTEGRATION")
    template(slot="modal-header")
      .brand-modal-header-with-background
        .d-flex.align-items-center
          .flex-grow-1.font-weight-bold {{ $t('newGlobalSetting') }}
        .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('new-campaign-integration')")
          close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body")
      .form-group.row.align-items-center
        label.col-sm-5.col-form-label(for="name") {{ $t('integrationFields.name') }}
        .col-sm-7
          input#name.form-control(
            type="text"
            :class="{ 'is-invalid': $v.currentGlobalIntegration.data.name.$error }"
            v-model="currentGlobalIntegration.data.name"
          )
      template(v-for="integration in integrations")
        template(
          v-if="currentGlobalIntegration.type === integration && integration !== 'shopRenter'"
        )
          template(
            v-if="(isOAuth(currentGlobalIntegration.type) && oAuthFields(currentGlobalIntegration.type).includes(field)) || !isOAuth(currentGlobalIntegration.type)"
            v-for="field in getIntegrationAuthFields(integration)"
          )
            integration-row(
              :type="currentGlobalIntegration.type"
              :fieldName="field"
              :integrationData.sync="currentGlobalIntegration.data"
            )
        ShopRenterGlobal(
          v-if="integration === 'shopRenter' && currentGlobalIntegration.type === integration"
          :global.sync="currentGlobalIntegration.data"
          :create="true"
          :campaignDomain="domain"
        )
      template(
        v-if="['smsBump', 'shopifyCustomer'].includes(currentGlobalIntegration.type) && (hasMoreThanOneActiveShopifyDomains || currentGlobalIntegration.type === 'shopifyCustomer')"
      )
        integration-shopify-select(:current-global-integration="currentGlobalIntegration")
      template(v-if="currentGlobalIntegration.type === 'getResponse'")
        .form-group.row.align-items-center
          .col-sm-5.col-form-label
            label(for="apiKey") {{ $t(`integrations.${currentGlobalIntegration.type}.fields.is360User`) }}
            om-tooltip.pl-1
              span(v-html="$t(`integrations.${currentGlobalIntegration.type}.tooltip.is360User`)")
          .col-sm-7
            toggle-button.mb-0(
              :labels="{ checked: $t('on'), unchecked: $t('off') }"
              :width="76"
              :height="24"
              v-model="currentGlobalIntegration.data.is360User"
            )
        integration-row(
          v-if="currentGlobalIntegration.data.is360User"
          :type="currentGlobalIntegration.type"
          fieldName="uniqueURL"
          :integrationData.sync="currentGlobalIntegration.data"
        )
      .campaign-integration-warnings.mt-3(
        v-if="$te(`integrations.${currentGlobalIntegration.type}.globalWarnings`)"
      )
        .font-weight-bold {{ $t('attention') }}!
        div(v-for="warning in $t(`integrations.${currentGlobalIntegration.type}.globalWarnings`)") {{ $t(`integrationWarnings.${warning}`) }}
    template(slot="modal-footer")
      .d-flex.justify-content-end
        om-button.mr-3(secondary @click="currentPage = PAGES.SELECT_GLOBAL_INTEGRATION") {{ $t('cancel') }}
        om-button(v-if="isOAuth(currentGlobalIntegration.type)" primary @click="oAuthLogin") {{ $t('authenticate') }}
        om-button(v-else primary :loading="loading" @click="saveGlobal") {{ $t('done') }}
  template(v-if="currentPage === PAGES.INTEGRATION_MAPPING")
    template(slot="modal-header")
      .brand-modal-header-with-background
        .d-flex.align-items-center
          .flex-grow-1.font-weight-bold {{ $t('integrationSettings') }}
        .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('new-campaign-integration')")
          close-icon(:width="12" :height="12" color="#AAB1C1")
    template(slot="modal-body")
      loading-logo(v-if="integrationLoading")
      template(v-if="integrationConnectError")
        .d-flex.justify-content-center.flex-column.align-items-center
          img(:src="require('@/components/Elements/Monks/svgs/sad.svg')")
          om-heading.mt-5(h2) {{ $t('integrationConnectFail') }}
          om-body-text(bt400lg) {{ $t('integrationConnectFailDetails') }}
      template(v-else)
        transition(name="fade" mode="in-out")
          div(v-show="!integrationLoading")
            global-integration-settings(
              :integration="currentGlobalIntegration"
              :domain="domain"
              @refetchQuery="$emit('refetchQuery', $event)"
            )
            template(
              v-if="['smsBump', 'shopifyCustomer'].includes(currentGlobalIntegration.type) && (hasMoreThanOneActiveShopifyDomains || currentGlobalIntegration.type === 'shopifyCustomer')"
            )
            // remove everything when smsbump or shopifyCustomer edit occurring
            component(
              v-if="!isGlobalOnlyIntegration(currentGlobalIntegration.type)"
              :is="camelCaseToDash(currentGlobalIntegration.type)"
              :tags="tags"
              :globalIntegration="currentGlobalIntegration"
              :savedIntegration="currentIntegration"
              :saveBtnClicked="saveBtnClicked"
              @loaded="integrationLoading = false"
              @saved="campaignIntegrationSaved"
              ref="integrationComp"
              @error="integrationConnectError = true"
            )
            .campaign-integration-warnings.mt-3(v-if="!globalIntegrationPing")
              .font-weight-bold {{ $t('attention') }}!
              div {{ $t(`integrationWarnings.ping_error`) }}
            .campaign-integration-warnings.mt-3(
              v-else-if="$te(`integrations.${currentGlobalIntegration.type}.warnings`)"
            )
              .font-weight-bold {{ $t('attention') }}!
              div(
                v-for="warning in $t(`integrations.${currentGlobalIntegration.type}.warnings`)"
                v-html="$t(`integrationWarnings.${warning}`)"
              )
    template(slot="modal-footer")
      .d-flex.justify-content-end
        om-button.mr-3(secondary @click="cancel") {{ $t('cancel') }}
        om-button(primary @click="saveCampaignIntegration" v-if="!integrationConnectError") {{ $t('done') }}
</template>

<script>
  import ADD_GLOBAL_INTEGRATION from '@/graphql/AddGlobalIntegration.gql';
  import ADD_CAMPAIGN_INTEGRATION from '@/graphql/AddCampaignIntegration.gql';
  import GET_CAMPAIGNS_BY_INTEGRATION from '@/graphql/GetCampaignsByIntegration.gql';
  import REMOVE_GLOBAL_INTEGRATION from '@/graphql/RemoveGlobalIntegration.gql';
  import PING_GLOBAL_INTEGRATION from '@/graphql/PingGlobalIntegration.gql';
  import EDIT_GLOBAL_INTEGRATION from '@/graphql/EditGlobalIntegration.gql';
  import IntegrationRow from '@/components/IntegrationRow.vue';
  import GlobalIntegrationSettings from '@/components/GlobalIntegrationSettings.vue';
  import IntegrationShopifySelect from '@/components/Modals/IntegrationShopifySelect.vue';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import camelCaseToDash from '@/mixins/camelCaseToDash';
  import * as Integrations from '@/components/IntegrationSettings/';
  import { isWhitelabelDomain } from '@/config/url';
  import {
    getConfigForIntegration,
    getIntegrationConfigs,
    getPhoneNumberIntegrations,
    getPhoneNumberOnlyIntegrations,
  } from '@om/integrations';
  import { required } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';
  import { getOauthRedirectUrl } from '@/utils/oauth';
  import { track } from '@/services/xray';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import { ACER_CCDB, ATTENTIVE_V2, NEW_INTEGRATION_FLOW } from '@/utils/features';
  import HasKlaviyoDetection from '@/components/CampaignSettings/Klaviyo/HasDetection.vue';
  import * as IntegrationNewFlowHelper from '@/helpers/integration/newIntegrationFlow';
  import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';

  const PAGES = {
    SELECT_GLOBAL_INTEGRATION: 1,
    CREATE_NEW_INTEGRATION: 2,
    INTEGRATION_MAPPING: 3,
  };

  export default {
    components: {
      IntegrationRow,
      HasKlaviyoDetection,
      GlobalIntegrationSettings,
      IntegrationLogo,
      IntegrationShopifySelect,
      IntegrationName: () => import('@/components/IntegrationName'),
      ...Integrations,
    },
    mixins: [camelCaseToDash],

    props: {
      globalIntegrations: {
        type: Array,
      },
      campaignIntegrations: {
        type: Array,
      },
      hasEmailField: {
        type: Boolean,
      },
      hasPhoneField: {
        type: Boolean,
      },
      domain: {
        type: String,
      },
    },

    data() {
      return {
        integrations: [],
        filter: '',
        currentPage: PAGES.SELECT_GLOBAL_INTEGRATION,
        currentGlobalIntegration: { type: '', data: {} },
        addNew: false,
        currentUrl: window.location.href,
        currentIntegration: {},
        editMode: false,
        saveBtnClicked: 0,
        loading: false,
        globalIntegrationPing: true,
        integrationLoading: true,
        PAGES,
        failedIntegrationResyncService: null,
        integrationConnectError: false,
      };
    },

    validations: {
      currentGlobalIntegration: {
        data: {
          name: { required },
        },
      },
    },

    computed: {
      ...mapGetters([
        'isSubUser',
        'hasActiveShopify',
        'getActiveShopifyDomains',
        'shopRenterAppSettings',
        'hasAccountFeature',
        'domains',
      ]),
      getDefaultIntegrations() {
        let integrations = [];
        if (!this.hasEmailField) {
          integrations = ['webhook'];

          if (this.hasPhoneField) {
            const phoneNumberIntegrations = getPhoneNumberIntegrations();
            integrations = [...integrations, ...phoneNumberIntegrations];
          }
        }
        return integrations;
      },

      filteredIntegrations() {
        if (this.getDefaultIntegrations.length) {
          return this.integrations.filter((i) => this.getDefaultIntegrations.includes(i));
        }

        const integrations = this.filter
          ? this.integrations.filter((i) =>
              this.$t(`integrations.${i}.name`).toLowerCase().includes(this.filter.toLowerCase()),
            )
          : this.integrations;

        if (!this.hasAccountFeature(ACER_CCDB)) {
          return integrations.filter((i) => i !== 'acerCCDB' && i !== 'acerCCDBV2');
        }

        if (!this.hasAccountFeature(ATTENTIVE_V2)) {
          return integrations.filter((i) => i !== 'attentiveV2');
        }
        return integrations;
      },

      hasGlobalIntegrations() {
        return this.globalIntegrations.length !== 0;
      },

      hasMoreThanOneActiveShopifyDomains() {
        return this.getActiveShopifyDomains.length > 1;
      },

      shopRenterActiveApps() {
        return this.shopRenterAppSettings.filter((a) => a.active && a.domains && a.domains.length);
      },

      integrationsByField() {
        return this.globalIntegrations.filter((g) => this.getDefaultIntegrations.includes(g.type));
      },
      tags() {
        if (
          getConfigForIntegration(this.currentGlobalIntegration.type)?.tagFields &&
          getConfigForIntegration(this.currentGlobalIntegration.type)?.tagFields.length
        ) {
          return getConfigForIntegration(this.currentGlobalIntegration.type)?.tagFields;
        }
        return null;
      },
    },

    watch: {
      addNew(n) {
        if (n) {
          const integrationsHolder = this.$refs.integrationsHolder;
          if (integrationsHolder) {
            integrationsHolder.scrollTop = 0;
          }

          this.$nextTick(() => {
            this.$refs.integrationSearchInput?.focus();
          });
        }
      },
      integrationConnectError(error) {
        if (error) {
          this.$notify({
            type: 'error',
            title: this.$t('integrationConnectFail'),
            text: this.$t('integrationConnectFailDetails'),
          });
        }
      },
    },

    methods: {
      async beforeOpen(event) {
        this.filter = '';
        this.addNew = false;
        this.saveIntegration = false;
        this.integrationConnectError = false;
        this.integrations = Object.keys(getIntegrationConfigs())
          .sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
          .filter(
            (type) =>
              this.filterOAuthForSubUser(type) &&
              this.filterShopifyOnly(type) &&
              this.filterByAvailableFields(type) &&
              !this.filterExcluded(type) &&
              !this.filterDeprecated(type),
          );
        this.saveBtnClicked = 0;
        this.globalIntegrationPing = true;
        this.integrationLoading = true;

        if (event.params?.integrationId) {
          this.currentPage = PAGES.INTEGRATION_MAPPING;
          this.currentIntegration = this.campaignIntegrations.find(
            (i) => i._id === event.params.integrationId,
          );
          this.currentGlobalIntegration = this.currentIntegration.global;
          this.editMode = true;

          this.globalIntegrationPing = await this.pingGlobalIntegration(
            this.currentGlobalIntegration.type,
            this.currentGlobalIntegration._id,
          );
          if (this.$refs.integrationComp?.init) {
            this.$refs.integrationComp.init();
          }
          if (['listamester', 'salesforce', 'bizzy'].includes(this.currentGlobalIntegration.type)) {
            this.integrationLoading = false;
          }
        } else if (event.params?.trigger === 'loadFromUrl' && event.params?.integration) {
          this.currentGlobalIntegration = event.params.integration;
          this.currentIntegration = {};
          this.currentPage = PAGES.INTEGRATION_MAPPING;
          this.integrationLoading = false;
        } else {
          this.currentPage = PAGES.SELECT_GLOBAL_INTEGRATION;
          this.currentGlobalIntegration = { type: '', data: {} };
          this.editMode = false;
        }

        if (event.params?.failedIntegrationResyncService) {
          this.failedIntegrationResyncService = event.params.failedIntegrationResyncService;
        }
      },
      hasKlaviyoCampaignIntegration() {
        const campaignIntegrations = this.campaignIntegrations;

        const campaignWithKlaviyo = campaignIntegrations.find(
          (element) => element.global.type === 'klaviyo',
        );
        return campaignWithKlaviyo;
      },
      domainHasKlaviyoDetection() {
        const campaignDomain = this.domain;
        const domains = this.domains;
        const domainWithKlaviyoDetection = domains.find(
          (element) => element.domain === campaignDomain && element.isKlaviyoDetected,
        );
        return domainWithKlaviyoDetection;
      },

      filterOAuthForSubUser(type) {
        return (
          !this.isOAuth(type) ||
          !this.isSubUser ||
          (!isWhitelabelDomain() && this.isSubUser && this.isOAuth(type))
        );
      },

      filterShopifyOnly(type) {
        return this.hasActiveShopify || (!this.hasActiveShopify && !this.isShopifyOnly(type));
      },

      filterExcluded(type) {
        return type === 'infusionSoft';
      },

      filterByAvailableFields(type) {
        if (!this.hasPhoneField) {
          const phoneOnlyIntegrations = getPhoneNumberOnlyIntegrations();

          return !phoneOnlyIntegrations.includes(type);
        }

        return true;
      },
      filterDeprecated(type) {
        return getConfigForIntegration(type).deprecated;
      },

      isNewIntegrationFlowAvailable(type) {
        return (
          this.hasAccountFeature(NEW_INTEGRATION_FLOW) &&
          IntegrationNewFlowHelper.isNewFlowAvailableFor(type)
        );
      },

      createFromNew(type) {
        if (this.isNewIntegrationFlowAvailable(type)) {
          track('creatingIntegration', { type, version: 2 });
          this.$modal.hide('new-campaign-integration');
          this.$modal.show('integration-new-flow', {
            integrationType: type,
            state: FLOW_STATES.NEW,
            failedIntegrationResyncService: this.failedIntegrationResyncService,
          });
          return;
        }

        track('creatingIntegration', { type });
        this.currentGlobalIntegration = { type, data: {} };
        this.setDefaultGlobalIntegrationDatas();
        this.currentIntegration = {};
        this.integrationLoading = true;
        this.currentPage = PAGES.CREATE_NEW_INTEGRATION;
        if (['listamester', 'bizzy'].includes(type)) {
          this.integrationLoading = false;
        }
        this.$v.$reset();
      },

      setDefaultGlobalIntegrationDatas() {
        const { type } = this.currentGlobalIntegration;

        if (type === 'smsBump' || type === 'shopifyCustomer') {
          const index = this.getActiveShopifyDomains.findIndex((s) => s.liveDomain === this.domain);
          this.currentGlobalIntegration.data.shopifyDomain =
            index === -1
              ? this.getActiveShopifyDomains[0].liveDomain
              : this.getActiveShopifyDomains[index].liveDomain;
        }
      },

      createFromGlobal(integration) {
        const { type } = integration;

        if (this.isNewIntegrationFlowAvailable(type)) {
          track('usingIntegration', { type, version: 2 });
          this.$modal.hide('new-campaign-integration');
          this.$modal.show('integration-new-flow', {
            integrationType: type,
            state: FLOW_STATES.SELECT_GLOBAL,
            globalIntegrationId: integration._id,
            failedIntegrationResyncService: this.failedIntegrationResyncService,
          });
          return;
        }

        track('usingIntegration', { type });
        if (!this.editingIntegrationName) {
          this.currentGlobalIntegration = integration;
          this.currentIntegration = {};
          this.integrationLoading = true;
          this.currentPage = PAGES.INTEGRATION_MAPPING;
          if (['listamester', 'salesforce', 'bizzy'].includes(type)) {
            this.integrationLoading = false;
          }
          // QUESTION Ping integration again?
        }
      },

      saveGlobal() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.loading = true;
          this.$apollo
            .mutate({
              mutation: ADD_GLOBAL_INTEGRATION,
              variables: {
                input: this.currentGlobalIntegration,
              },
            })
            .then(({ data: { addIntegration } }) => {
              if (addIntegration.success) {
                this.$emit('refetchQuery', 'accountSettings');
                this.currentGlobalIntegration._id = addIntegration.id;

                if (this.currentGlobalIntegration.type === 'actOn') {
                  delete this.currentGlobalIntegration.data.username;
                  delete this.currentGlobalIntegration.data.password;
                }

                this.currentPage = PAGES.INTEGRATION_MAPPING;
                this.loading = false;
              } else {
                this.$notify({
                  type: 'error',
                  text: this.$t('notifications.apiConnectError'),
                });

                this.loading = false;
              }
            });
        }
      },

      removeGlobal(integrationId) {
        this.$apollo
          .query({
            query: GET_CAMPAIGNS_BY_INTEGRATION,
            variables: {
              integrationId,
            },
          })
          .then(({ data: { campaigns } }) => {
            if (campaigns.length) {
              this.$modal.show('integration-remove', { campaigns, integrationId });
            } else {
              this.$modal.show('dialog', {
                text: this.$t('confirmationDialog'),
                buttons: [
                  {
                    title: this.$t('yes'),
                    class: getBrandedClassString({ primary: true }, 'mr-3'),
                    handler: () => {
                      this.$apollo
                        .mutate({
                          mutation: REMOVE_GLOBAL_INTEGRATION,
                          variables: {
                            integrationId,
                          },
                        })
                        .then(() => {
                          this.$emit('refetchQuery', 'accountSettings');
                          this.$modal.hide('dialog');
                        });
                    },
                  },
                  {
                    title: this.$t('cancel'),
                    class: getBrandedClassString({ secondary: true }),
                    default: true,
                  },
                ],
              });
            }
          });
      },

      getIntegrationAuthFields(type) {
        return getConfigForIntegration(type).authFields;
      },

      campaignIntegrationSaved(savedIntegration) {
        const index = this.campaignIntegrations.findIndex((c) => c._id === savedIntegration._id);
        if (index !== -1) {
          this.$set(this.campaignIntegrations, index, savedIntegration);
        } else {
          // eslint-disable-next-line
          this.campaignIntegrations.push(savedIntegration);
        }

        this.$emit('update:campaignIntegrations', this.campaignIntegrations);
        this.$modal.hide('new-campaign-integration');
      },

      cancel() {
        if (this.editMode) {
          this.$modal.hide('new-campaign-integration');
        } else {
          this.currentPage = PAGES.SELECT_GLOBAL_INTEGRATION;
        }
      },

      async oAuthLogin() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else if (this.currentGlobalIntegration.type === 'acerCCDBV2') {
          const state = window.btoa(
            `${this.currentGlobalIntegration.data.name}|${this.$route.params.id}`,
          );
          const response = await this.$axios.get(`/integration-oauth/acerCCDB?state=${state}`);

          this.$emit('refetchQuery', 'accountSettings');
          this.currentGlobalIntegration._id = response.data.integrationId;
          this.currentPage = PAGES.INTEGRATION_MAPPING;
          this.loading = false;
          this.integrationLoading = false;
        } else {
          window.location.href = getOauthRedirectUrl(
            this.currentGlobalIntegration,
            this.$route.params.id,
          );
        }
      },

      isOAuth(type) {
        return getConfigForIntegration(type)?.isOAuth;
      },

      isShopifyOnly(type) {
        return getConfigForIntegration(type)?.shopifyOnly;
      },

      oAuthFields(type) {
        return getConfigForIntegration(type)?.oAuthFields || [];
      },

      successfulNameChange() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.saveSuccess'),
        });
        this.$emit('refetchQuery', 'accountSettings');
        this.$emit('refetchQuery', 'campaignSettings');
      },

      failedNameChange() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
      },

      focusGlobalIntegrationName(evt) {
        this.editingIntegrationName = true;
        this.editedIntegration = evt.integrationId;
        // this.tempIntegrationName = this.globalIntegrations[index].data.name
        this.$refs[`globalIntegrationInput_${evt.integrationId}`][0].focus();
      },

      saveCampaignIntegration() {
        if (this.$refs.integrationComp && this.$refs.integrationComp.checkBinding) {
          const validation = this.$refs.integrationComp.checkBinding();
          if (!validation) {
            const { failedValidationMessage: transKey, requiredFieldValidation } =
              getConfigForIntegration(this.currentGlobalIntegration.type);
            const fieldList = requiredFieldValidation[0]
              .slice(1)
              .map((id) => `${id.charAt(0).toUpperCase()}${id.substring(1)}`)
              .join(', ');

            this.$notify({
              type: 'error',
              text: this.$t(`integrationWarnings.validations.${transKey}`, { fieldList }),
            });
            return;
          }
        }

        if (this.isGlobalOnlyIntegration(this.currentGlobalIntegration.type) && !this.editMode) {
          this.$apollo
            .mutate({
              mutation: ADD_CAMPAIGN_INTEGRATION,
              variables: {
                campaignId: this.$route.params.id,
                input: {
                  id: this.currentGlobalIntegration._id,
                },
              },
            })
            .then(({ data: { addCampaignIntegration } }) => {
              this.campaignIntegrationSaved({
                _id: addCampaignIntegration,
                id: this.currentGlobalIntegration._id,
                global: this.currentGlobalIntegration,
              });
              this.$modal.hide('new-campaign-integration');
            });
        } else if (
          this.isGlobalOnlyIntegration(this.currentGlobalIntegration.type) &&
          this.editMode
        ) {
          this.$modal.hide('new-campaign-integration');
        } else if (
          this.currentGlobalIntegration.type === 'shopRenter' &&
          this.currentGlobalIntegration.data.transformed &&
          !this.$v.$invalid
        ) {
          const { name, apiUrl, username, password, identifier } =
            this.currentGlobalIntegration.data;
          this.$apollo
            .mutate({
              mutation: EDIT_GLOBAL_INTEGRATION,
              variables: {
                integrationId: this.currentGlobalIntegration._id,
                input: {
                  type: this.currentGlobalIntegration.type,
                  data: { name, apiUrl, username, password, identifier },
                },
              },
            })
            .then(({ data: { editIntegration } }) => {
              if (editIntegration.success) {
                this.$emit('refetchQuery', 'accountSettings');
                this.$notify({
                  type: 'success',
                  text: this.$t('notifications.saveSuccess'),
                });
                this.saveBtnClicked++;
              } else {
                this.$notify({
                  type: 'error',
                  text: this.$t('notifications.apiConnectError'),
                });
              }
            });
        } else {
          this.saveBtnClicked++;
        }

        // HOTFIX: not every integration has validation
        const isIntegrationInvalid = this.$refs.integrationComp?.$v?.$invalid ?? false;
        if (!this.failedIntegrationResyncService || isIntegrationInvalid) return;

        this.$modal.show('lead-sync-modal', {
          totalLeads: this.failedIntegrationResyncService.getErrorCount(),
          campaignId: this.$route.params.id,
          serviceInstance: this.failedIntegrationResyncService,
          newIntegrationData: this.currentGlobalIntegration,
        });
      },

      async pingGlobalIntegration(integrationType, integrationId) {
        try {
          const {
            data: { success },
          } = await this.$apollo.query({
            query: PING_GLOBAL_INTEGRATION,
            variables: {
              integrationType,
              integrationId,
            },
          });

          return success;
        } catch (error) {
          return false;
        }
      },

      isGlobalOnlyIntegration(type) {
        if (type === 'shopRenter') return !this.shopRenterActiveApps.length;
        return getConfigForIntegration(type).isGlobalOnly;
      },
    },
  };
</script>

<style lang="sass">
  .new-campaign-integration-modal
    .keep-logo-height.brand-integration-shop-renter-logo
      .brand-integration-logo-img
        height: 100%
</style>
